
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.contentStyle {
    max-width: 746px;
    @include lg {
        width: 65%;
    }

    h2 {
        text-transform: capitalize;
        color: $cpap-main-color;
        font-weight: bold;
        font-size: 19px;
        line-height: 28.5px;
        @include lg {
            font-size: 25px;
            line-height: 37.5px;
        }
    }
    span {
        width: 104px;
        display: inline-block;
        height: 2px;
        background-color: $cpap-main-color;
    }
    p {
        font-size: 16px;
        line-height: 28px;
        @include lg {
            font-size: 18px;
            line-height: 32px;
        }
    }
    p:first-of-type {
        margin-top: 20px;
        @include lg {
            margin-top: 30px;
        }
    }
}
